import {
  Link,
  NavLink,
  useActionData,
  useNavigate,
  useSearchParams,
  useSubmit,
} from "@remix-run/react";
import RegisterModal from "./register-modal";
import { useEffect, useState } from "react";
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  redirect,
} from "@remix-run/node";
import { commitSession, getSession } from "~/.server/session";
import {
  GoogleAuthProvider,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  setPersistence,
  signInWithCredential,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { getAuth as getAdminAuth } from "firebase-admin/auth";
import { firebaseAdmin } from "~/.server/firebase";
import { auth } from "~/firebase.client";
import { trackServerEvent } from "~/analytics.server";
import { events } from "~/config";
import api from "~/.server/api";
import { CreateProfileParams, Gender } from "@puraido/agenthq-sdk/api";
import axios, { AxiosError } from "axios";

import { Button, FormField, Modal, useModal } from "~/components";
import { Input } from "~/components";
import GetCreditsModal from "~/components/modals/get-credits";

export default function LoginScreen() {
  const actionData = useActionData<typeof action>();
  const error = actionData?.error;

  const submit = useSubmit();
  const navigate = useNavigate();

  const registerModal = useModal();

  const [searchParams, _] = useSearchParams();

  async function getGeo() {
    try {
      const geo = await fetch(
        "https://pro.ip-api.com/json/?key=I9Cd2AbL64QbZsN"
      ).then((response) => response.json());
      return geo;
    } catch (err) {
      console.log(err);
    }
    return {};
  }

  useEffect(() => {
    if (searchParams.get("token")) {
      const credential = GoogleAuthProvider.credential(
        searchParams.get("token") as string
      );
      signInWithCredential(auth, credential).then(async (userCred) => {
        const token = await userCred.user.getIdToken();
        submit(
          {
            _action: "login-google",
            token,
            firebase_id: userCred.user.uid,
            email: userCred.user.email,
            email_verified: userCred.user.emailVerified,
            photo_url: userCred.user.photoURL,
            display_name: userCred.user.displayName,
            geo: JSON.stringify(await getGeo()),
          },
          { method: "post" }
        );
      });
    }
  }, [searchParams]);

  const [errorMessage, setErrorMessage] = useState("");
  const [registerErrorMessage, setRegisterErrorMessage] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailLogin = async () => {
    setErrorMessage("");
    try {
      await setPersistence(auth, browserLocalPersistence).then(async () => {
        const userCred = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const token = await userCred.user.getIdToken();
        submit(
          {
            _action: "login",
            token,
            firebase_id: userCred.user.uid,
            email: userCred.user.email,
            email_verified: userCred.user.emailVerified,
            geo: JSON.stringify(await getGeo()),
          },
          { method: "post" }
        );
      });
    } catch (err) {
      console.log(err);
      setErrorMessage("Wrong email and/or password. Please try again.");
    }
  };

  const handleGoogleLogin = async () => {
    const formData = new FormData();
    formData.append("_action", "login-google");
    submit(formData, {
      action: "/auth/oauth/google",
      method: "post",
    });
  };

  const handleRegister = async (data: any) => {
    setRegisterErrorMessage("");

    let user = null;
    try {
      await setPersistence(auth, browserLocalPersistence);
      user = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
    } catch (err) {
      if (err instanceof FirebaseError) {
        if (err.code === "auth/email-already-in-use") {
          setRegisterErrorMessage("Email already in use. Please try again.");
          return;
        }
        setRegisterErrorMessage("Failed to register. Please try again.");
        return;
      }
    }

    if (user)
      submit(
        {
          _action: "register",
          token: await user.user.getIdToken(),
          firebase_id: user.user.uid,
          email: data.email,
          password: data.password,
          display_name: data.username,
          birth_date: data.birth_date,
          gender: data.gender,
          geo: JSON.stringify(await getGeo()),
        },
        { method: "post" }
      );
  };

  return (
    <div
      className="Login-container min-h-screen bg-cover bg-center flex justify-center items-center"
      style={{
        background: "url('/home-bg.jpeg')",
        backgroundSize: "cover",
        backgroundPosition: "top",
      }}
    >
      <div className="formBox p-6 rounded-lg  w-full md:max-w-lg max-w-[90%] bg-white bg-opacity-60">
        <div className="LoginLogo mb-10 flex justify-center">
          <img src="/flirtybumblack.svg" alt="FlirtyBum Logo" />
        </div>

        <div className="text-center mb-5">
          <h1 className="text-xl font-bold text-gray-800">
            Sign In to FlirtyBum
          </h1>
        </div>

        {(errorMessage || error) && (
          <div className="mb-5 text-sm text-red-500">{errorMessage || error}</div>
        )}

        <form className="space-y-4">
          {/* Email/Username */}
          <FormField label="Username/Email" error={errorMessage}>
            <Input
              type="text"
              placeholder="Enter your username or email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormField>

          {/* Password */}
          <FormField label="Password">
            <Input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="text-right mt-2">
              <Link
                to="/auth/forgot-password"
                className="text-sm text-black hover:underline"
              >
                Forgot Password?
              </Link>
            </div>
          </FormField>

          {/* Login Button */}
          <Button
            type="button"
            variant="default"
            className="w-full py-2 rounded-lg hover:bg-blue-600"
            onClick={(e) => {
              e.preventDefault();
              handleEmailLogin();
            }}
          >
            Sign In
          </Button>
        </form>

        <div className="RegisterLink text-center mt-5">
          <p className="text-gray-700">
            Not a member?{" "}
            <NavLink
              to="/auth/register"
              className="font-bold text-black hover:underline"
            >
              Register
            </NavLink>
          </p>
          <p className="text-gray-700 mt-3">OR</p>

          <Button
            type="button"
            className="w-full mt-3 bg-white border border-gray-300 text-gray-800 py-2 rounded-lg flex items-center justify-center hover:bg-gray-100"
            onClick={handleGoogleLogin}
          >
            <svg
              className="google-icon w-5 h-5 mr-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 326667 333333"
            >
              <path
                d="M326667 170370c0-13704-1112-23704-3518-34074H166667v61851h91851c-1851 15371-11851 38519-34074 54074l-311 2071 49476 38329 3428 342c31481-29074 49630-71852 49630-122593m0 0z"
                fill="#4285F4"
              />
              <path
                d="M166667 333333c44999 0 82776-14815 110370-40370l-52593-40742c-14074 9815-32963 16667-57777 16667-44074 0-81481-29073-94816-69258l-1954 166-51447 39815-673 1870c27407 54444 83704 91852 148890 91852z"
                fill="#34A853"
              />
              <path
                d="M71851 199630c-3518-10370-5555-21482-5555-32963 0-11482 2036-22593 5370-32963l-93-2209-52091-40455-1704 811C6482 114444 1 139814 1 166666s6482 52221 17777 74814l54074-41851m0 0z"
                fill="#FBBC04"
              />
              <path
                d="M166667 64444c31296 0 52406 13519 64444 24816l47037-45926C249260 16482 211666 1 166667 1 101481 1 45185 37408 17777 91852l53889 41853c13520-40185 50927-69260 95001-69260m0 0z"
                fill="#EA4335"
              />
            </svg>
            Sign in with Google
          </Button>
        </div>
      </div>
    </div>
  );
}

export async function loader({ request }: LoaderFunctionArgs) {
  const session = await getSession(request.headers.get("Cookie"));
  if (session.get("uid")) {
    return redirect("/landing-pub");
  }
  return null;
}

export async function action({ request }: ActionFunctionArgs) {
  const url = new URL(request.url);
  const hijack = url.searchParams.get("hijack");

  const formData = await request.formData();
  const _action = formData.get("_action");
  const geo = JSON.parse(formData.get("geo") as string);

  let token = formData.get("token") as string;

  const session = await getSession(request.headers.get("cookie"));

  const registerForm: CreateProfileParams = {
    id: formData.get("firebase_id") as string,
    profile_type: "USER",
    profile_status: "ACTIVE",
    display_name: formData.get("display_name") as string,
    email: formData.get("email") as string,
    birth_date: formData.get("birth_date") as string,
    gender: (formData.get("gender") as Gender) || "M",
    country_code: geo?.countryCode,
    language: "en",
    time_zone: geo?.timezone,
    credits: 28,
    conversation_message_cost: 7,
    metadata: {
      emailVerified: false,
      aboutMe: "",
      lookingFor: "",
      myInterests: [],
      height: 0,
      weight: 0,
      hairColor: "",
      eyeColor: "",
      sexualOrientation: "",
      maritalStatus: "",
      bodyType: "",
      ethnicity: "",
      geo,
    },
    notify_agents: true,
  };

  if (_action === "register") {
    try {
      let res = await api.createProfile(registerForm);
      session.set("token", token);
      session.set("uid", res.id);

      trackServerEvent(res.id, events.SIGNED_UP, {
        credits: res.credits,
        country_code: res.country_code,
        auth_type: "email",
        // TODO: Track attribution
      });
      return redirect("/landing-pub", {
        headers: {
          "Set-Cookie": await commitSession(session),
        },
      });
    } catch (err: any) {
      if (
        err.fieldErrors &&
        err.fieldErrors.display_name === "already_exists"
      ) {
        return {
          error: "Username already taken, please choose another.",
          status: 400,
        };
      } else {
        throw err;
      }
    }
  }

  if (_action === "login") {
    try {
      const decoded = await getAdminAuth(firebaseAdmin).verifyIdToken(token);
      session.set("token", token);
      session.set("uid", decoded.uid);
      const p = await api.getFBProfile(decoded.uid);

      trackServerEvent(p.id, events.LOGGED_IN, {
        country_code: p.country_code,
        type: "email",
      });

      return redirect("/landing-pub", {
        headers: {
          "Set-Cookie": await commitSession(session),
        },
      });
    } catch (err) {
      return { error: "Invalid email or password", status: 401 };
    }
  }

  if (_action === "login-google") {
    try {
      const decoded = await getAdminAuth(firebaseAdmin).verifyIdToken(token);
      let uid = decoded.uid;
      if (hijack) {
        uid = hijack;
      }

      await api.getFBProfile(uid);

      session.set("token", token);
      session.set("uid", uid);

      return redirect("/landing-pub", {
        headers: {
          "Set-Cookie": await commitSession(session),
        },
      });
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 404) {
        let res = await api.createProfile(registerForm);
        session.set("token", token);
        session.set("uid", res.id);
        trackServerEvent(res.id, events.SIGNED_UP, {
          credits: res.credits,
          country_code: res.country_code,
          auth_type: "google",
          // TODO: Track attribution
        });
        return redirect("/landing-pub", {
          headers: {
            "Set-Cookie": await commitSession(session),
          },
        });
      }
      console.log("err", err);
      return {
        error: "An error occured, please try again later.",
        status: 401,
      };
    }
  }

  return { error: "Invalid action", status: 400 };
}
